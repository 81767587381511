import { Dialog as BeesDialog, Heading, Paragraph } from '@hexa-ui/components';
import { ChevronLeftIcon, ChevronRightIcon } from 'assets/icons';
import { useDialogContext } from 'providers/DialogProvider';
import { useState } from 'react';
import {
  ArrowContainer,
  ArrowsWrapper,
  HeaderWrapper,
  InlineActionsWrapper,
  MessagesCarousel,
  MessagesCount,
  StackedActionsWrapper,
  StyledParagraph,
} from './styles';

export type DialogProps = {
  content?: JSX.Element;
  description?: string | string[];
  title?: string;
  stackedActions?: boolean;
  actions?: JSX.Element[] | JSX.Element;
  severalMessagesText?: string;
  contentCss?: React.CSSProperties;
};

const Dialog = () => {
  const { props, isOpen } = useDialogContext();
  const [actualDescriptionIdx, setActualDescriptionIdx] = useState<number>(0);

  const handleDescription = () => {
    return Array.isArray(props.description) ? (
      <>
        <Paragraph size="small">{`${props.description.length} ${
          props.severalMessagesText || 'messages'
        }`}</Paragraph>
        <StyledParagraph> {props.description[actualDescriptionIdx]}</StyledParagraph>
      </>
    ) : (
      <StyledParagraph> {props.description}</StyledParagraph>
    );
  };

  return (
    <BeesDialog.Root
      variant="overlay"
      open={isOpen}
      closeButton={false}
      title={
        Array.isArray(props.description) ? (
          <HeaderWrapper>
            <Heading>{props.title}</Heading>
            <MessagesCarousel>
              <ArrowsWrapper>
                <ArrowContainer
                  onClick={() => {
                    if (actualDescriptionIdx > 0) setActualDescriptionIdx((cur) => cur - 1);
                  }}
                  placement="left"
                  disabled={actualDescriptionIdx === 0}
                  data-testid="arrow-back"
                >
                  <ChevronLeftIcon size="medium" />
                </ArrowContainer>
                <ArrowContainer
                  onClick={() => {
                    if (actualDescriptionIdx < props.description.length - 1)
                      setActualDescriptionIdx((cur) => cur + 1);
                  }}
                  placement="right"
                  disabled={actualDescriptionIdx === props.description.length - 1}
                  data-testid="arrow-forward"
                >
                  <ChevronRightIcon size="medium" />
                </ArrowContainer>
              </ArrowsWrapper>
              <MessagesCount>
                <Paragraph size="small">{`${actualDescriptionIdx + 1}/${
                  props.description.length
                }`}</Paragraph>
              </MessagesCount>
            </MessagesCarousel>
          </HeaderWrapper>
        ) : (
          <Heading>{props.title}</Heading>
        )
      }
      actions={
        props.stackedActions ? (
          <StackedActionsWrapper>{props.actions}</StackedActionsWrapper>
        ) : (
          <InlineActionsWrapper>{props.actions}</InlineActionsWrapper>
        )
      }
      contentCss={{ overflowY: 'initial', ...props.contentCss }}
    >
      {props.description ? handleDescription() : props.content}
    </BeesDialog.Root>
  );
};

export default Dialog;
