export const VERTICAL_MANAGEMENT_SEARCH_FILTERS = 'vertical-management-search-filters';
export const VERTICAL_REGISTRATION = 'vertical-registration';
export const VERTICAL_EDIT = 'vertical-edit';
export const VERTICAL_STRUCTURES_LIST = 'vertical-registration-structure-list';
export const VERTICAL_GO_TO_STRUCTURES = 'vertical-go-to-structure';
export const VERTICAL_DELETE = 'vertical-delete';
export const STRUCTURE_DELETE = 'structure-delete';
export const VALUE_STREAM_TYPE_DELETE = 'value-stream-type-delete';
export const FILTERED_REPORT_ALLOCATION = 'people-management-full-history-filter';
export const ADD_STRUCTURE_BY_VERTICAL_FORM = 'add-structure-by-vertical-form';
export const NEW_ADD_PERSON_FORM = 'new-add-person-form';
