export const SCOPES = {
  ADMIN_READ: 'CapacityManagement.Admin.Read',

  APP_READ: 'CapacityManagement.App.Read',

  BULK_PEOPLE_CREATE: 'CapacityManagement.Bulk.PeopleCreate',

  CAPACITY_MANAGEMENT_READ: 'CapacityManagement.CapacityManagement.Read',
  CAPACITY_MANAGEMENT_ADMIN_PLANNING: 'CapacityManagement.CapacityManagement.AdminPlanning',

  COMPANY_CREATE: 'CapacityManagement.Company.Create',
  COMPANY_READ: 'CapacityManagement.Company.Read',
  COMPANY_UPDATE: 'CapacityManagement.Company.Update',
  COMPANY_DELETE: 'CapacityManagement.Company.Delete',
  COMPANY_INACTIVATE: 'CapacityManagement.Company.Inactivate',

  DEVELOPER_READ: 'CapacityManagement.Developer.Read',

  FORECAST_DOWNLOAD_PLAN: 'CapacityManagement.Forecast.DownloadPlan',
  FORECAST_UPLOAD_PLAN: 'CapacityManagement.Forecast.UploadPlan',
  FORECAST_HISTORY: 'CapacityManagement.Forecast.History',
  FORECAST_WRITE_FORECAST: 'CapacityManagement.Forecast.WriteForecast',
  FORECAST_WRITE_VALIDATED: 'CapacityManagement.Forecast.WriteValidated',
  FORECAST_WRITE_PLAN: 'CapacityManagement.Forecast.WritePlan',

  LAST_MONTH_ALLOCATION_UPDATE: 'CapacityManagement.LastMonthAllocation.Update',

  MEETING_CREATE: 'CapacityManagement.Meeting.Create',
  MEETING_READ: 'CapacityManagement.Meeting.Read',
  MEETING_DELETE: 'CapacityManagement.Meeting.Delete',

  PARAMETER_READ: 'CapacityManagement.Parameter.Read',
  PARAMETER_UPDATE: 'CapacityManagement.Parameter.Update',

  PEOPLE_ALLOCATION_LESS_THAN_ONE: 'CapacityManagement.People.AllocationLessThanOne',
  PEOPLE_CREATE_ALL: 'CapacityManagement.People.CreateAll',
  PEOPLE_READ_ALL: 'CapacityManagement.People.ReadAll',
  PEOPLE_UPDATE_ALL: 'CapacityManagement.People.UpdateAll',
  PEOPLE_DELETE: 'CapacityManagement.People.Delete',
  PEOPLE_INACTIVATE: 'CapacityManagement.People.Inactivate',
  PEOPLE_REACTIVATE: 'CapacityManagement.People.Reactivate',
  PEOPLE_CREATE_SELF: 'CapacityManagement.People.CreateSelf',
  PEOPLE_READ_SELF: 'CapacityManagement.People.ReadSelf',
  PEOPLE_UPDATE_SELF: 'CapacityManagement.People.UpdateSelf',
  PEOPLE_UPDATE_ALLOCATION: 'CapacityManagement.People.UpdateAllocation',
  PEOPLE_WRITE_IS_LINE_MANAGER: 'CapacityManagement.People.WriteIsLineManager',
  PEOPLE_DOWNLOAD_HISTORY: 'CapacityManagement.People.DownloadHistory',
  PEOPLE_COMPANY_COLUMN_GRID_READ: 'CapacityManagement.People.CompanyColumnGridRead',
  PEOPLE_COMPANY_FILTER_READ: 'CapacityManagement.People.CompanyFilterRead',
  PEOPLE_DOWNLOAD_PEOPLE_UUID: 'CapacityManagement.People.DownloadReportUUID',
  PEOPLE_WRITE_ABI_EMAIL: 'CapacityManagement.People.WriteAbiEmail',
  PEOPLE_NO_ALLOCATION_WRITE: 'CapacityManagement.People.NoAllocationWrite',
  PEOPLE_FILTER_STATUS_READ: 'CapacityManagement.People.FilterStatusRead',

  ROLE_CREATE: 'CapacityManagement.Role.Create',
  ROLE_READ: 'CapacityManagement.Role.Read',
  ROLE_UPDATE: 'CapacityManagement.Role.Update',
  ROLE_DELETE: 'CapacityManagement.Role.Delete',
  ROLE_INACTIVATE: 'CapacityManagement.Role.Inactivate',
  ROLE_MERGE: 'CapacityManagement.Role.Merge',
  ROLE_MOVE_PEOPLE: 'CapacityManagement.Role.MovePeople',

  ROLE_GROUP_CREATE: 'CapacityManagement.RoleGroup.Create',
  ROLE_GROUP_READ: 'CapacityManagement.RoleGroup.Read',
  ROLE_GROUP_UPDATE: 'CapacityManagement.RoleGroup.Update',
  ROLE_GROUP_INACTIVATE: 'CapacityManagement.RoleGroup.Inactivate',

  STRUCTURE_CREATE: 'CapacityManagement.Structure.Create',
  STRUCTURE_READ: 'CapacityManagement.Structure.Read',
  STRUCTURE_UPDATE: 'CapacityManagement.Structure.Update',
  STRUCTURE_DELETE: 'CapacityManagement.Structure.Delete',

  TEAM_CREATE: 'CapacityManagement.Team.Create',
  TEAM_READ: 'CapacityManagement.Team.Read',
  TEAM_UPDATE: 'CapacityManagement.Team.Update',
  TEAM_DELETE: 'CapacityManagement.Team.Delete',
  TEAM_INACTIVATE: 'CapacityManagement.Team.Inactivate',
  TEAM_ADD_PERSON: 'CapacityManagement.Team.AddPerson',
  TEAM_CONFIRM_DATA: 'CapacityManagement.Team.ConfirmData',
  TEAM_FULL_REPORT: 'CapacityManagement.Team.FullReport',
  TEAM_REPORT_STATUS_READ: 'CapacityManagement.Team.ReportStatusRead',
  TEAM_REPORT_STATUS_READ_GUARDIAN: 'CapacityManagement.Team.ReportStatusReadGuardian',
  TEAM_UPDATE_GUARDIAN: 'CapacityManagement.Team.UpdateGuardians',

  VALUE_STREAM_CREATE: 'CapacityManagement.ValueStream.Create',
  VALUE_STREAM_READ: 'CapacityManagement.ValueStream.Read',
  VALUE_STREAM_UPDATE: 'CapacityManagement.ValueStream.Update',
  VALUE_STREAM_DELETE: 'CapacityManagement.ValueStream.Delete',
  VALUE_STREAM_INACTIVATE: 'CapacityManagement.ValueStream.Inactivate',
  VALUE_STREAM_TYPE_READ: 'CapacityManagement.ValueStreamType.Read',
  VALUE_STREAM_TYPE_UPDATE: 'CapacityManagement.ValueStreamType.Update',
  VALUE_STREAM_TYPE_WRITE: 'CapacityManagement.ValueStreamType.Write',
  VALUE_STREAM_TYPE_DELETE: 'CapacityManagement.ValueStreamType.Delete',

  VERTICAL_READ: 'CapacityManagement.Vertical.Read',
  VERTICAL_WRITE: 'CapacityManagement.Vertical.Write',
  VERTICAL_UPDATE: 'CapacityManagement.Vertical.Update',
  VERTICAL_DELETE: 'CapacityManagement.Vertical.Delete',
};
