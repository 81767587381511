import { Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const StackedActionsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
});

export const InlineActionsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: '$1',
});

export const StyledParagraph = styled(Paragraph, {
  marginTop: '10px !important',
  maxWidth: '24rem',
});

export const HeaderWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const ArrowsWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const MessagesCarousel = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexFlow: 'column',
  gap: '$2',
});

export const ArrowContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',

  variants: {
    disabled: {
      false: {
        cursor: 'pointer',
      },
      true: {
        cursor: 'initial',
        color: '$interfaceLabelDisabled',
      },
    },
    placement: {
      right: {
        marginLeft: '$2',
      },
      left: {
        marginRight: '$2',
      },
    },
  },
});

export const MessagesCount = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
});
